import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import { useGetGameListMutation } from "../../redux/api/gamesApi";
import { useTypedSelector } from "../../hooks/useTypedRedux";
import Search from "../../ui/material-kit/Search";
import useInput from "../../hooks/useInput";
import CategoriesContainer from "../../components/containers/CategoriesContainer";

import ProviderAccordion from "../../ui/ProviderAccordion";
import { useDebounce } from "../../hooks/useDebounce";
import GamesListContainerDesktop from "./GamesListContainer.desktop";
import GamesVirtualListContainerDesktop from "./GamesVirtualListContainer.desktop";

const GamesPageContainerDesktop = () => {
  const { t } = useTranslation();
  const search = useInput("");

  const fields = {
    search: t(`common.placeholder.search_games`),
    password: t(`common.placeholder.password`),
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [provider, setProvider] = useState<string>(
    searchParams.get("provider") || ""
  );
  const findGames = useDebounce(search.value, 1000);
  const [categoryFilter, setCategoryFilter] = useState<string>(
    searchParams.get("category") || ""
  );

  const changeCategory = useCallback(
    (v: string) => {
      setSearchParams({ provider: provider, category: v });
    },
    [categoryFilter, provider]
  );

  const changeProvider = useCallback(
    (v: string) => {
      if (v === provider) {
        setSearchParams({ provider: "", category: categoryFilter });
        return;
      }
      setSearchParams({ provider: v, category: categoryFilter });
    },
    [categoryFilter, provider]
  );

  const memoValue = useMemo(() => {
    return {
      game: findGames || undefined,
      provider: provider || undefined,
      category: categoryFilter || undefined,
    };
  }, [findGames, provider, categoryFilter]);

  useEffect(() => {
    const prov = searchParams.get("provider") || "";
    const cat = searchParams.get("category") || "";
    if (prov !== provider) setProvider(prov);
    if (cat !== categoryFilter) setCategoryFilter(cat);
  }, [searchParams]);

  return (
    <Wrapper>
      <Search search={search} title={fields.search} isMobile={false} />
      <ProviderAccordion
        changeProvider={changeProvider}
        currentProvider={provider}
      />
      <CategoriesContainer
        onChange={changeCategory}
        category={categoryFilter}
      />
      <div style={{ margin: -8 }}>
        <GamesListContainerDesktop value={memoValue} />
        <GamesVirtualListContainerDesktop value={memoValue} />
      </div>
      {/*<GamesVirtualListContainerDesktop value={memoValue}/>*/}
    </Wrapper>
  );
};

export default GamesPageContainerDesktop;

const Wrapper = styled.div`
  display: grid;
  gap: 18px;
`;
