import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css, CSSProperties } from "styled-components";
import { ReactComponent as FavoriteClickedIcon } from "assets/images/game_card/game_favorite_clicked_icon.svg";
import { IGame } from "../redux/types/gamesTypes";
import { useTranslation } from "react-i18next";
import Button from "./material-kit/Button";
import { useTypedSelector } from "../hooks/useTypedRedux";
import IconButton from "./material-kit/IconButton";
import { useToggleFavoriteGameSessionMutation } from "../redux/api/gamesApi";
import LazyImage from "./LazyImage";
import useDimensions from "react-cool-dimensions";
import { useCustomToast } from "../hooks/useCustomToast";
import { userIdSelector } from "../redux/selectors/userSelector";
import { isMobileSelector } from "../redux/selectors/appSelector";
import { useAverageColor } from "hooks/useAverageColor";
import { device } from "constants/deviceSizes";

interface IGameCard {
  game: IGame;
  isTv?: boolean;
  style?: CSSProperties;
  padding?: number;
  isAuth?: boolean;
  isLazy?: boolean;
}

const projectName = process.env.REACT_APP_INSTANCE;

const GameCard = (props: IGameCard) => {
  const { t } = useTranslation();
  const { game, isTv, style, isLazy } = props;
  const [isFavorite, setFavorite] = useState(game.IsFavorite || false);
  const [toggleFavoriteGame] = useToggleFavoriteGameSessionMutation();
  const [gradient, calcGradient] = useAverageColor();

  useEffect(() => {
    setFavorite(game.IsFavorite);
  }, [game]);

  const isAuth = !!useTypedSelector(userIdSelector);
  const isMobile = useTypedSelector(isMobileSelector);

  const callToast = useCustomToast({
    text: t("messages.alert.please_login"),
    type: "error",
  });

  const openGame = useCallback(() => {
    if (isAuth) {
      sessionStorage.setItem("previousPage", window.location.href);
      window.location.href = `/game/${game.SectionId}/${game.Id}`;
    } else {
      callToast();
    }
  }, [callToast, game.Id, game.SectionId, isAuth]);

  const handleFavoriteGame = useCallback(
    (id: string) => {
      if (!isAuth) {
        callToast();
      } else {
        toggleFavoriteGame({ Id: id, type: "gameList" }).then((pld) =>
          "data" in pld ? setFavorite(!isFavorite) : undefined
        );
      }
    },
    [callToast, isAuth, isFavorite, toggleFavoriteGame]
  );

  const { observe, height } = useDimensions({});

  const count = useMemo(() => {
    return Math.floor(height / 21);
  }, [height]);

  return (
    <>
      {!isMobile ? (
        <Wrapper style={style} key={game.Id}>
          {isLazy ? (
            <GameLazy
              isTv={isTv}
              src={game.Thumbnail}
              alt={game.Name}
              onLoad={
                projectName === "megawin"
                  ? (event) => calcGradient(event.target as HTMLImageElement)
                  : undefined
              }
              crossOrigin={
                projectName === "megawin" ? "use-credentials" : undefined
              }
            />
          ) : (
            <Game
              isTv={isTv}
              src={game.Thumbnail}
              alt={game.Name}
              onLoad={
                projectName === "megawin"
                  ? (event) => calcGradient(event.target as HTMLImageElement)
                  : undefined
              }
              crossOrigin={
                projectName === "megawin" ? "use-credentials" : undefined
              }
            />
          )}
          {projectName === "megawin" && (
            <Gradient gradient={gradient}>
              <span>{game.Name}</span>
              <span>{game.SectionId}</span>
            </Gradient>
          )}
          <HoverContainer>
            {!isTv && (
              <Icon
                isFavorite={isFavorite}
                onClick={(e) => {
                  e?.stopPropagation();
                  handleFavoriteGame(game.Id);
                }}
                id={game.Name}
              >
                <FavoriteClickedIcon />
              </Icon>
            )}
            <Text ref={observe} count={count > 4 ? 4 : count}>
              {game.Name}
            </Text>
            <StButton
              color={"primary"}
              onClick={openGame}
              id={`play-game-${game.Name}`}
            >
              {t(`pages.game.play`)}
            </StButton>
          </HoverContainer>
        </Wrapper>
      ) : (
        <Wrapper style={style} onClick={openGame} isMobile isTv={isTv}>
          {isLazy ? (
            <GameLazy
              isTv={isTv}
              src={game.Thumbnail}
              alt={game.Name}
              onLoad={
                projectName === "megawin"
                  ? (event) => calcGradient(event.target as HTMLImageElement)
                  : undefined
              }
              crossOrigin={
                projectName === "megawin" ? "use-credentials" : undefined
              }
            />
          ) : (
            <Game
              isTv={isTv}
              src={game.Thumbnail}
              alt={game.Name}
              onLoad={
                projectName === "megawin"
                  ? (event) => calcGradient(event.target as HTMLImageElement)
                  : undefined
              }
              crossOrigin={
                projectName === "megawin" ? "use-credentials" : undefined
              }
            />
          )}
          {projectName === "mybet" && (
            <MobileTextWrapper>
              <MobileText>{game.Name}</MobileText>
            </MobileTextWrapper>
          )}
          {projectName === "megawin" && (
            <Gradient gradient={gradient}>
              <span>{game.Name}</span>
              <span>{game.SectionId}</span>
            </Gradient>
          )}
          {!isTv && (
            <MobileIcon
              id={`card-${game.Name}`}
              isFavorite={isFavorite}
              onClick={(e) => {
                e?.stopPropagation();
                handleFavoriteGame(game.Id);
              }}
            >
              <FavoriteClickedIcon />
            </MobileIcon>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default React.memo(GameCard);

const Gradient = styled.div<{ gradient?: string }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: ${({ gradient }) =>
    gradient
      ? `linear-gradient(180deg, rgba(185, 141, 255, 0) 0%, ${gradient} 60.14%)`
      : "transparent"};

  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding-bottom: 14px;
  gap: 8px;

  span:first-child,
  span:last-child {
    width: 100%;
    max-height: 50%;
    font-size: 18px;
    line-height: 15px;
    font-weight: 900;
    padding: 0 8px;
    height: fit-content;
    text-align: center;
    box-sizing: border-box;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-shadow: 0 0 3.18px #3c2a47;
  }

  span:last-child {
    font-size: 12px;
    line-height: 10px;
  }

  @media (${device.laptopL}) {
    span:first-child {
      font-size: 16px;
      line-height: 15px;
    }
  }

  @media (${device.tabletM}) {
    span:first-child {
      font-size: 14px;
      line-height: 12px;
    }

    span:last-child {
      font-size: 10px;
      line-height: 8px;
    }
  }
`;

const Wrapper = styled.div<{ isMobile?: boolean; isTv?: boolean }>`
  overflow: hidden;
  cursor: pointer;
  position: relative;
  border-radius: ${projectName !== "megawin" ? 5 : 15}px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  span {
    font-size: 0;
    width: 100%;
    height: ${({ isMobile, isTv }) => (isMobile && isTv ? "auto" : "100%")};
  }

  ${projectName === "megawin" &&
  css`
    height: auto;
    aspect-ratio: 3 / 4;
  `}
`;

const MobileText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const MobileTextWrapper = styled.div`
  border-radius: 0 0 5px 5px;
  display: flex;
  min-width: 0;
  padding: 0 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  //position: absolute;
  min-height: 28px;
  height: 28px;
  font-size: 10px;
  opacity: 0.85;
  background-color: black;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Text = styled.div<{ count?: number }>`
  -webkit-line-clamp: ${({ count }) => count};
  display: -webkit-box;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const MobileIcon = styled(IconButton)<{ isFavorite?: boolean }>`
  position: absolute;
  width: 20%;
  height: 20%;
  top: 12px;
  right: 12px;

  & svg > g {
    transition: 0.2s;
    opacity: ${({ isFavorite }) => (isFavorite ? "1" : "0.3")};

    path {
      fill: ${({ isFavorite }) => !isFavorite && "#000000"};
    }
  }
`;

const Icon = styled(IconButton)<{
  isFavorite?: boolean;
}>`
  width: max-content !important;
  height: 100% !important;
  align-items: self-end;

  & svg {
    max-width: 58px;
    max-height: 52px;
    width: 100%;
    height: 100%;

    g {
      transition: 0.2s;
      opacity: ${({ isFavorite }) => (!isFavorite ? "0.3" : "1")};

      path {
        fill: ${({ isFavorite }) => !isFavorite && "#000000"};
      }
    }
  }
`;

const Game = styled.img<{ isTv?: boolean }>`
  border-radius: 5px;
  font-size: 0;
  width: 100%;
  height: ${projectName === "megawin" ? "85%" : "100%"};
  object-fit: cover;
  object-position: center center;
  overflow: hidden;
  ${({ isTv }) =>
    isTv &&
    css`
      aspect-ratio: ${projectName === "megawin" ? 3 / 4 : 2 / 1};
    `}
`;

const GameLazy = styled(LazyImage)<{ isTv?: boolean }>`
  border-radius: 5px;
  font-size: 0;
  width: 100%;
  height: ${projectName === "megawin" ? "85%" : "100%"};
  object-fit: cover;
  object-position: center center;
  overflow: hidden;
  ${({ isTv }) =>
    isTv &&
    css`
      aspect-ratio: ${projectName === "megawin" ? 3 / 4 : 2 / 1};
    `}
`;

const HoverContainer = styled.div`
  padding: 10px;
  display: grid;
  grid-auto-rows: 1fr;
  justify-items: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 18px;
  text-transform: uppercase;

  :hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.85);
  }
`;

const StButton = styled(Button)`
  color: ${({ theme }) => theme.palette.text.main};
  opacity: 1;
  width: 113px;
  min-width: 113px;
  max-height: 42px;
`;
