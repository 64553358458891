export enum TABLES {}

export enum SORT_ORDERS {
  ASC = "ASC",
  DESC = "DESC",
}

export enum CAPTCHA_KEY {
  CLIENT_KEY = "6LfLSiwgAAAAAA3GaQGM6_EFJ7ukkooUfYgkQMde",
}

export const STORAGE_KEY = "uniquekeygoodwingames";

// соотношение сторон карточек игр
export const MEGAWIN_GAMES_ASPECT_RATIO = 3 / 4;
