import React, { useEffect } from "react";
import { useTypedSelector } from "hooks/useTypedRedux";
import { useTranslation } from "react-i18next";

import styled, { css } from "styled-components";
import BannerImageMegawin from "assets/images/games_banner_icon_megawin.webp";
import BannerImageMybet from "assets/images/games_banner_icon_mybet_mob.webp";
import MostLikedGamesContainer from "components/containers/MostLikedGamesContainer";
import GamesPageContainer from "./GamesPageContainer";
import { useGetProvidersQuery } from "../../redux/api/gamesApi";
import ContainerTitle from "../../ui/ContainerTitle";
import { useGetJackpotMutation } from "../../redux/api/appApi";
import JackpotStarsMobile from "../../ui/JackpotStarMobile";
import { jackpotSelector } from "../../redux/selectors/appSelector";
import { userSelector } from "../../redux/selectors/userSelector";

const projectName = process.env.REACT_APP_INSTANCE;

const Games = () => {
  const { t } = useTranslation();
  const {} = useGetProvidersQuery();
  const [getJackpot] = useGetJackpotMutation();

  useEffect(() => {
    getJackpot();
  }, [getJackpot]);

  const jackpot = useTypedSelector(jackpotSelector);
  const isAuth = useTypedSelector(userSelector);

  return (
    <>
      <Banner>
        {isAuth && !!jackpot.length && (
          <div>
            <JackpotStarsMobile />
          </div>
        )}
        <Image
          alt={"banner"}
          src={projectName === "mybet" ? BannerImageMybet : BannerImageMegawin}
        />
      </Banner>
      <Container>
        <GamesPageContainer />
      </Container>
    </>
  );
};

export default Games;

const Container = styled.div`
  z-index: 1;
  gap: 12px;
  padding: 0 10px;
  display: grid;
  grid-auto-rows: min-content;
`;

const Banner = styled.div`
  width: 100%;
  position: relative;
  justify-self: center;
`;

const Image = styled.img`
  width: 100vw;
  height: clamp(10.125rem, 0.285rem + 41.985vw, 20.438rem);
  margin: 0;

  ${projectName === "megawin" &&
  css`
    object-fit: cover;
    object-position: center;
  `}
`;
