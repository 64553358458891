import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import { getStorage } from "hooks/useStorage";
import MainCard from "../../ui/cards/MainCard";
import { useGetGameListMutation } from "../../redux/api/gamesApi";
import { useTypedSelector } from "../../hooks/useTypedRedux";
import ImageIcon from "../../ui/material-kit/ImageIcon";
import ContainerTitle from "../../ui/ContainerTitle";
import { device } from "../../constants/deviceSizes";
import GameCard from "../../ui/GameCard";
import useDimensions from "react-cool-dimensions";
import { favoriteGamesSelector } from "../../redux/selectors/gamesSelector";

const MostLikedGamesContainer = () => {
  const [getGameList] = useGetGameListMutation();

  const data = useTypedSelector(favoriteGamesSelector);

  useEffect(() => {
    getGameList({
      data: {
        type: "favorite",
        size: 4,
      },
    });
  }, [getGameList]);

  const { observe, width } = useDimensions({});

  return (
    <Wrapper ref={observe} width={width}>
      {data.slice(0, 4).map((item, index) => (
        <React.Fragment key={`promotion-card-${index}`}>
          <GameCard game={item} />
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

export default MostLikedGamesContainer;

const Wrapper = styled.div<{ width: number }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  gap: 17px;
  grid-auto-rows: ${({ width }) => (width ? (width / 4) * 0.75 : 100)}px;
  @media (${device.tabletS}) {
    gap: 6px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(
      2,
      ${({ width }) => (width ? (width / 2) * 0.75 : 100)}px
    );
  }
`;

const Item = styled(ImageIcon)`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;
